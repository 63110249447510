<template>
  <div class="live-platform_container livePlatform">
    <ListScroll class="livePlatfrom_block" :url="$urls.live.playList">
      <template v-slot:listItem="{ cItems }">
        <LiveItem :item="cItems"></LiveItem>
      </template>
    </ListScroll>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import ListScroll from "@/components/unit/ListScroll";
import LiveItem from "./liveItem";
export default {
  name: "livePlatform ",
  components: {
    CustomButton,
    ListScroll,
    LiveItem,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style >
.live-platform_container {
  height: 100%;
  width: 100%;
  font-size: 0.8rem;
  padding: 0.5rem;
  /* background: #f8f8f8; */
}

.livePlatfrom_block {
  height: 100%;
}

/* .qq {
  height: 100vh;
} */
</style>