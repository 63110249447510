<template>
  <div class="livePlatfrom_block_item">
    <div class="live_status_block">
      <div>
        <span class="colorRed fwb">会议主题：</span>
        <span>{{ data.title }}</span>
      </div>
      <div class="live_status colorRed">
        {{ $config.types.meetStatus[data.tx_meet_status] }}
      </div>
    </div>
    <div class="livePlatfrom_meetInfo">
      <div>
        <span class="colorRed fwb"> 会议时间：</span
        ><span
          >{{ data.meet_start_time.substr(0, 16) }}-{{
            data.meet_end_time.substring(11, 16)
          }}</span
        >
      </div>
    </div>

    <div class="livePlatfrom_block_item_meetInfo">
      <div
        class="livePlatfrom_meetInfo_item"
        v-for="(doctorInfo, doctorIndex) in getRoles()"
        :key="doctorIndex"
      >
        <div>
          <span class="livePlatfrom_meetInfo_item_role">
            <span
              class="role"
              :class="
                [$config.types.zx, $config.types.jz].includes(doctorInfo.role)
                  ? 'colorRed'
                  : ''
              "
              >{{ $config.types.roleMap[doctorInfo.role] }}</span
            >{{ doctorInfo.name }}
          </span>
          <div class="livePlatfrom_meetInfo_item_hospital">
            {{ doctorInfo.hospital }}
          </div>
        </div>
      </div>
    </div>
    <div class="group_btn">
      <CustomButton
        @click="goToLive"
        size="small"
        :type="button[data.tx_meet_status]"
        >{{ getButtonName() }}</CustomButton
      >
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "homeItem",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      button: ["custom", "primary", "primary"],
      data: {},
      tempRoles: [],
    };
  },
  created() {
    this.data = { ...this.item };
  },
  mounted() {},
  methods: {
    getRoles() {
      let zhuxis = this.data[this.$config.types.roles.zx].map((person) => {
        person.role = this.$config.types.roles.zx;
        return person;
      });
      let jiangzhes = this.data[this.$config.types.roles.jz].map((person) => {
        person.role = this.$config.types.roles.jz;
        return person;
      });
      let taoluns = this.data[this.$config.types.roles.tl].map((person) => {
        person.role = this.$config.types.roles.tl;
        return person;
      });
      return [...zhuxis, ...jiangzhes, ...taoluns];
    },
    getButtonName() {
      switch (this.data.tx_meet_status) {
        case 0:
          return "即将开始，敬请期待";
          break;
        case 10:
          return "进入直播";
          break;
        case 20:
          return "已结束";
          break;
      }
    },
    goToLive() {
      if (this.data.tx_meet_status !== 10) return;
      this.$router.push({
        path: "/live",
        query: {
          id: this.data.id,
        },
      });
    },
  },
};
</script>
<style scoped>
.livePlatfrom_block_item {
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #f0c2c2;
  margin-bottom: 1rem;
  padding: 1rem 0 0 0;
  position: relative;
}

.live_status_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 6rem);
}
.live_status_block > div {
  display: flex;
}
.live_status_block > div > span:last-child {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.live_status_block:first-child {
  padding-left: 1rem;
  font-size: 0.9rem !important;
}
.live_status_block:last-child {
  font-weight: 600;
}

.live_status {
  padding: 1rem 0;
  background: #fff6f7;
  border-radius: 0 0.25rem 0 0.25rem;
  white-space: nowrap;
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;
  text-align: center;
  justify-content: center;
}

.livePlatfrom_meetInfo {
  margin: 0rem 0 1rem;
  font-size: 0.9rem;
}

.livePlatfrom_meetInfo div {
  line-height: 1.5;
  display: flex;
  padding-left: 1rem;
}

.livePlatfrom_meetInfo div > span:first-child {
  white-space: nowrap;
}

.livePlatfrom_block_item_meetInfo {
  background: #fff6f7;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;
}

.livePlatfrom_meetInfo_item {
  /* height: 5rem; */
  margin: 0 1rem 0.5rem 0;
  width: calc(50% - 0.5rem);
  background: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 0.2rem -0.1rem #e64638;
}

.livePlatfrom_block_item_meetInfo .livePlatfrom_meetInfo_item:nth-child(2n) {
  margin-right: 0;
}

.livePlatfrom_block_item_meetInfo .livePlatfrom_meetInfo_item:nth-child(3) {
  /* margin-bottom: 0; */
}
.livePlatfrom_block_item_meetInfo .livePlatfrom_meetInfo_item:nth-child(4) {
  /* margin-bottom: 0; */
}
.livePlatfrom_block_item_meetInfo .livePlatfrom_meetInfo_item:nth-child(5) {
  margin-bottom: 0;
}

.livePlatfrom_meetInfo_item_role {
  margin-bottom: 0.5rem;
}

.livePlatfrom_meetInfo_item_role .role {
  margin-right: 0.5rem;
  font-weight: bold;
  font-weight: 0.9rem;
}

.group_btn {
  margin: 1rem 1rem;
  color: #fff;
}
.group_btn .customButton_container {
  font-size: 0.9rem !important;
}
.livePlatfrom_meetInfo_item_hospital {
  color: rgb(113, 113, 113);
  margin-top: 0.5rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fwb {
  font-weight: bold;
  white-space: nowrap;
}
</style>